/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Text, Title, Button, Subtitle, Fullmap, FullmapWrap, FullmapCover, ContactForm, Menu, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="mb--20" name={"einleitung"}>
          
          <ColumnWrap className="column__flex --center mb--60 mt--16">
            
            <ColumnWrapper >
              
              <Image className="mb--20" alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/o/g/Ikonky/Kadernictvi/dcc8f590acaf4d2f9dcbe3cfe1a9a7dc.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":450}} srcSet={""} content={null} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Text className="text-box swpf--uppercase ls--20" style={{"color":"rgb(0, 0, 0)"}} content={"Barber Parlour & Social Club"}>
              </Text>

              <Title className="title-box fs--72 swpf--uppercase mt--02" content={"Club & Barbershop"}>
              </Title>

              <Text className="text-box w--500 mt--20" style={{"maxWidth":900}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--style2 btn-box--filling2 w--600 swpf--uppercase mt--25" href={"#presliste"} content={"Dienstleistungen"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/55/fullscreen_s=350x_.jpg"} svg={false} ratio={"16:9"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/55/fullscreen_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/55/fullscreen_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/55/fullscreen_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/55/fullscreen_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/55/fullscreen_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--20" name={"unser-team"}>
          
          <ColumnWrap className="column__flex --center el--4" style={{"maxWidth":1160}} columns={"4"}>
            
            <ColumnWrapper className="--style1">
              
              <Title className="W--400 title-box title-box--style1" content={"Hansen Peterson"}>
              </Title>

              <Text className="text-box mt--16" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--style2 btn-box--filling2 btn-box--invert swpf--uppercase mt--16" href={"#buchung"} content={"Bestellung"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--style1">
              
              <Title className="W--400 title-box title-box--style1" content={"Peter Ratt"}>
              </Title>

              <Text className="text-box mt--16" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--style2 btn-box--filling2 btn-box--invert swpf--uppercase mt--16" href={"#buchung"} content={"Bestellung"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--style1">
              
              <Title className="W--400 title-box title-box--style1" content={"Nikole Benner"}>
              </Title>

              <Text className="text-box mt--16" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--style2 btn-box--filling2 btn-box--invert swpf--uppercase mt--16" href={"#buchung"} content={"Bestellung"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--style1">
              
              <Title className="W--400 title-box title-box--style1" content={"Jakob Wassermann"}>
              </Title>

              <Text className="text-box mt--16" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--style2 btn-box--filling2 btn-box--invert swpf--uppercase mt--16" href={"#buchung"} content={"Bestellung"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1ior5lm bg--center --parallax pb--80 pt--80" name={"buchung"} style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} parallax={true} css={css`
        
    background-image: url(https://cdn.swbpg.com/t/i/template/55/img-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/55/img-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/55/img-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/55/img-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/55/img-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/55/img-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/55/img-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/55/img-2_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--style1 pb--40 pl--20 pr--20 pt--40" style={{"maxWidth":520,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
              
              <Title className="title-box title-box--style1 swpf--uppercase" content={"Langjährige Erfahrung"}>
              </Title>

              <Text className="text-box mt--16" style={{"color":"rgb(0,0,0)","maxWidth":380}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--style2 btn-box--filling2 swpf--uppercase mt--16" href={"#buchung"} content={"Bestellung"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--40" name={"presliste"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1160}} columns={"1"}>
            
            <ColumnWrapper className="--style1 pl--0 pr--0">
              
              <Title className="title-box title-box--style1 swpf--uppercase" content={"Barbershop seit 1991"}>
              </Title>

              <Text className="text-box mt--16" style={{"color":"rgb(0,0,0)"}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mb--60 pb--30" name={"unser-team-2"} layout={"l12"}>
          
          <ColumnWrap className="column__flex el--4 flex--center" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/55/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/55/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/55/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/55/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/55/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/55/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--30 pr--30 pt--20">
              
              <Title className="title-box swpf--uppercase ls--01" content={"Komplette Bearbeitung"}>
              </Title>

              <Text className="text-box" style={{"color":"rgb(0, 0, 0)"}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

              <Title className="title-box ff--3 w--300 lh--1 mt--06" content={"— 17€"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/55/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/55/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/55/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/55/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/55/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/55/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--10 pl--30 pr--30 pt--10">
              
              <Title className="title-box swpf--uppercase ls--01" content={"Haare schneiden"}>
              </Title>

              <Text className="text-box" style={{"color":"rgb(0, 0, 0)"}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

              <Title className="title-box ff--3 w--300 lh--1 mt--06" content={"— 17€"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="pb--10 pl--30 pr--30 pt--10">
              
              <Title className="title-box swpf--uppercase ls--01" content={"Kinn-Bart schneiden"}>
              </Title>

              <Text className="text-box" style={{"color":"rgb(0, 0, 0)"}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

              <Title className="title-box ff--3 w--300 lh--1 mt--06" content={"— 17€"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/55/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/55/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/55/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/55/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/55/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/55/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--10 pl--30 pr--30 pt--10">
              
              <Title className="title-box swpf--uppercase ls--01" content={"Rasieren"}>
              </Title>

              <Text className="text-box" style={{"color":"rgb(0, 0, 0)"}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

              <Title className="title-box ff--3 w--300 lh--1 mt--06" content={"— 17€"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/55/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/55/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/55/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/55/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/55/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/55/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-b4w48f bg--top --parallax pb--60 pt--60" name={"kontakt"} style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} parallax={true} css={css`
        
    background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(https://cdn.swbpg.com/t/i/template/55/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(https://cdn.swbpg.com/t/i/template/55/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(https://cdn.swbpg.com/t/i/template/55/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(https://cdn.swbpg.com/t/i/template/55/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(https://cdn.swbpg.com/t/i/template/55/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(https://cdn.swbpg.com/t/i/template/55/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(https://cdn.swbpg.com/t/i/template/55/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(https://cdn.swbpg.com/t/i/template/55/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" columns={"3"}>
            
            <ColumnWrapper className="pb--20 pt--20">
              
              <Title className="title-box title-box--invert swpf--uppercase" style={{"maxWidth":360}} content={"Kontakt"}>
              </Title>

              <Text className="text-box text-box--invert mt--16" style={{"maxWidth":360}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--40 pt--30" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
              
              <Subtitle className="subtitle-box" style={{"maxWidth":360}} content={"+49 797 811 2X05"}>
              </Subtitle>

              <Text className="text-box mt--16" style={{"maxWidth":360}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

              <Subtitle className="subtitle-box mt--16" style={{"maxWidth":360}} content={"info@vase-stranky.com"}>
              </Subtitle>

              <Text className="text-box mt--16" style={{"maxWidth":360}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--50 pt--40" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
              
              <Title className="title-box swpf--uppercase" style={{"maxWidth":360}} content={"Club & Barbershop"}>
              </Title>

              <Text className="text-box mt--16" style={{"maxWidth":360}} content={"Oeder Weg 22"}>
              </Text>

              <Text className="text-box mt--0" style={{"maxWidth":360}} content={"Brno-Stadt"}>
              </Text>

              <Text className="text-box mt--0" style={{"maxWidth":360}} content={"Tschechische Republik"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--style2 btn-box--filling2 btn-box--invert" content={"In einer Karte anzeigen"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="full-map full-map--style2 pb--30" name={"karte"} style={{"paddingBottom":null,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <FullmapWrap className="full-map__wrap--full full-map__wrap">
            
            <FullmapCover className="full-map__cover" place={"Brno"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Column className="pb--30 pt--30" name={"buchung"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box swpf--uppercase" content={"Bestellung"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Ganze Name","type":"text","required":true,"placeholder":"Bitte tragen Sie Ihren Namen ein"},{"name":"E-Mail Kontakt:","type":"email","required":true,"placeholder":"Bitte geben Sie Ihre e-Mail Adresse ein"},{"name":"Telefon Nr.","type":"text","placeholder":"Telefon Nr."},{"name":"Nachricht","type":"textarea","required":true,"placeholder":"Bitte geben Sie Text Ihrer Nachricht ein"},{"name":"Versenden","type":"submit"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--menu pb--08 pt--20" menu={true} name={"partei"}>
          
          <Menu style={{"justify-content":"space-between"}}>
            
            <ColumnWrapper className="menu-logo-box">
              
              <Text className="text-box ff--1 w--600 swpf--uppercase ls--12" style={{"color":"rgb(0,0,0)"}} content={"Barber Parlour & Social Club"}>
              </Text>

              <Subtitle className="subtitle-box ff--3 w--400 swpf--uppercase ls--04 mt--0" content={"Club & Barbershop"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton className="--hvr2 --filling2">
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--style2 fs--16 w--600 btn-box--invert lh--1" href={"#presliste"} target={""} content={"Dienstleistungen"}>
                </Button>

                <Button className="btn-box btn-box--style2 fs--16 w--600 btn-box--invert lh--1" href={"#kontakt"} target={""} content={"Kontakt"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="pt--10" name={"partei-2"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="pt--0" style={{"paddingBottom":2,"backgroundColor":"var(--color-variable-1)"}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"partei-3"}>
          
          <ColumnWrap className="column__flex --center el--1 pb--20 pt--16 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" style={{"color":"rgb(0, 0, 0)"}} content={"Erstellt mit <a href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}